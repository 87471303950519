import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

function Main(){
  const [loader, setLoader] = React.useState(true);

  useEffect(()=>{
    //listen to window events
    window.addEventListener('domain_settings_loaded', function() {
      setLoader(false);
    });
  },[])
  if(!loader) return <></>;
  return <App />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);